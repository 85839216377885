import React, { useContext } from 'react';

import { Grid, Header, Segment } from 'semantic-ui-react';

import { ScreenSizeContext } from '../../contexts/ScreenContext';

const Porque = () => {
	const screenSize = useContext(ScreenSizeContext);

	return (
		<Segment
			id="porque"
			basic
			style={{
				backgroundImage: 'url("/img/sports/football.jpg")',
				backgroundRepeat: 'no-repeat',
				backgroundAttachment: 'fixed',
				backgroundSize: 'cover',
				color: 'white',
				padding: '100px 0px',
			}}
		>
			<Grid stackable container>
				<Grid.Row>
					<Grid.Column width={5} style={{ padding: 0 }}>
						<Segment
							style={{
								backgroundColor: 'rgba(0, 0, 0, 0.23)',
								borderTop: '1px solid rgba(169, 207, 70)',
								borderLeft: '1px solid rgba(169, 207, 70)',
								borderBottom: '1px solid rgba(169, 207, 70)',
								...(screenSize === 'mobile'
									? {
											borderRight: '1px solid rgba(169, 207, 70)',
											borderRadius: '15px',
										}
									: { borderRight: 'none', borderRadius: '15px 0 0 15px' }),
							}}
						>
							<Header
								style={{
									color: 'white',
									fontWeight: 'bolder',
									textTransform: 'uppercase',
								}}
							>
								POR QUE NOS ESCOLHER
							</Header>
							<p
								style={{
									fontSize: '1.1em',
									lineHeight: '1.7em',
								}}
							>
								Nossa plataforma foi criada para atender às necessidades dos
								gestores de eventos e torneios esportivos de todas as
								modalidades, oferecendo uma solução completa e automatizada que
								facilita desde o processo de inscrição até a atualização em
								tempo real de rankings.
							</p>
						</Segment>
					</Grid.Column>
					<Grid.Column width={11} style={{ padding: 0 }}>
						<Segment
							style={{
								backgroundColor: 'rgba(0, 0, 0, 0.23)',
								border: '1px solid rgba(169, 207, 70)',
								...(screenSize !== 'mobile'
									? { borderRadius: '0 15px 15px 15px' }
									: { borderRadius: '15px' }),
							}}
						>
							<Segment
								compact
								style={{
									backgroundColor: 'rgba(87, 99, 55, 0.4)',
								}}
							>
								<Header
									size="small"
									style={{
										color: 'white',
										fontWeight: 'bolder',
										textTransform: 'uppercase',
									}}
								>
									Automação completa: economize tempo e reduza erros
								</Header>
								<p
									style={{
										fontSize: '1.1em',
										lineHeight: '1.7em',
									}}
								>
									Com o Ponto do Esporte, todos os processos que antes
									demandavam horas de trabalho manual agora são automatizados.
									Desde a inscrição dos atletas até a formatação de chaves e
									tabelas, passando pela marcação de jogos e atualização de
									resultados, tudo é feito de maneira rápida e precisa. Diga
									adeus às planilhas confusas e ao risco de erros humanos.
								</p>
							</Segment>
							<Segment
								compact
								style={{
									backgroundColor: 'rgba(87, 99, 55, 0.4)',
								}}
							>
								<Header
									size="small"
									style={{
										color: 'white',
										fontWeight: 'bolder',
										textTransform: 'uppercase',
									}}
								>
									Atualização em tempo real: mantenha todos informados
								</Header>
								<p
									style={{
										fontSize: '1.1em',
										lineHeight: '1.7em',
									}}
								>
									Não perca mais tempo atualizando rankings manualmente. Nossa
									plataforma permite que os resultados e pontuações sejam
									atualizados em tempo real, mantendo todos os participantes
									informados e garantindo que a competição seja justa e
									transparente
								</p>
							</Segment>
							<Segment
								compact
								style={{
									backgroundColor: 'rgba(87, 99, 55, 0.4)',
								}}
							>
								<Header
									size="small"
									style={{
										color: 'white',
										fontWeight: 'bolder',
										textTransform: 'uppercase',
									}}
								>
									Experiência de usuário intuitiva: facilite a vida dos
									participantes
								</Header>
								<p
									style={{
										fontSize: '1.1em',
										lineHeight: '1.7em',
									}}
								>
									Desenvolvemos o Ponto do Esporte com uma interface amigável e
									intuitiva, permitindo que qualquer pessoa, independentemente
									de sua familiaridade com tecnologia, consiga navegar
									facilmente pela plataforma. Os jogadores podem acompanhar sua
									evolução no torneio, visualizar o desempenho em competições
									passadas e se inscrever em novos eventos com apenas alguns
									cliques.
								</p>
							</Segment>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default Porque;
