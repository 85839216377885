import React from 'react';

import { useHistory } from 'react-router';
import { Button, Grid, Header, Segment } from 'semantic-ui-react';

const Mudanca = () => {
	const history = useHistory();

	return (
		<Segment
			id="mudanca"
			basic
			style={{
				backgroundImage: 'url("/img/pe/parallax.jpg")',
				backgroundRepeat: 'no-repeat',
				backgroundAttachment: 'fixed',
				backgroundSize: 'cover',
				color: 'white',
				padding: '100px 0px',
			}}
		>
			<Grid stackable container centered>
				<Grid.Column computer={7} verticalAlign="middle">
					<Grid.Row textAlign="left" verticalAlign="bottom">
						<Header
							size="huge"
							textAlign="left"
							style={{
								color: 'white',
								textTransform: 'uppercase',
							}}
						>
							SEJA PARTE DA MUDANÇA
							<br />
							NO MUNDO DO ESPORTE
						</Header>
					</Grid.Row>
					<Grid.Row>
						<p
							style={{
								textAlign: 'justify',
								fontSize: '1.5em',
							}}
						>
							Experimente a plataforma Ponto do Esporte de graça! Estamos
							oferecendo um evento gratuito para que você teste todas as
							funcionalidades e descubra como podemos transformar a gestão dos
							seus torneios esportivos.
						</p>
						<p
							style={{
								textAlign: 'justify',
								fontSize: '1.5em',
							}}
						>
							Acredite no poder da automação e na simplicidade do Ponto do
							Esporte para transformar seus eventos esportivos. Não perca mais
							tempo com processos manuais e complicados.
						</p>
					</Grid.Row>
					<Grid.Row verticalAlign="middle" textAlign="left">
						<Button
							compact
							inverted
							floated="left"
							size="large"
							style={{
								borderRadius: '15px',
								margin: '2em 0 0 0',
							}}
							onClick={() => history.push('/organizations')}
						>
							CADASTRE A SUA ORGANIZAÇÃO
						</Button>

						<Button
							as="a"
							color="black"
							compact
							inverted
							floated="right"
							size="large"
							data-openwidget-action="maximize"
							style={{
								borderRadius: '15px',
								margin: '2em 0 0 0',
							}}
						>
							FALE CONOSCO
						</Button>
					</Grid.Row>
				</Grid.Column>
				<Grid.Column computer={9} />
			</Grid>
		</Segment>
	);
};

export default Mudanca;
