import React from 'react';

import { useScreenSize } from '../hooks';
import { ScreenSizeContext } from './ScreenContext';

export const ScreenSizeProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const screenSize = useScreenSize();

	return (
		<ScreenSizeContext.Provider value={screenSize}>
			{children}
		</ScreenSizeContext.Provider>
	);
};
