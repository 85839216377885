import React, { useContext } from 'react';

import { Link, useHistory } from 'react-router-dom';
import {
	Menu,
	Image,
	Dropdown,
	Segment,
	Icon,
	DropdownItemProps,
	Placeholder,
} from 'semantic-ui-react';

import { Item } from '../../app';
import { ScreenSizeContext, TScreenSize } from '../../contexts/ScreenContext';
import { useDomain } from '../../hooks';
import { useAppSelector } from '../../store/hooks';

type ProfileOptions = {
	show: TScreenSize[];
};

interface iHeader {
	activeItem: Item;
	openSidebar: boolean;
	handleOpenSidebar: () => void;
	handleCloseSidebar: () => void;
	handleMenuClick: (name: Item) => void;
}

const Header: React.FC<iHeader> = ({
	activeItem,
	openSidebar,
	handleOpenSidebar,
	handleCloseSidebar,
	handleMenuClick,
}) => {
	const screenSize = useContext(ScreenSizeContext);
	const history = useHistory();
	const domain = useDomain();
	const site = `${domain.protocol}//${domain.host}`;

	const { attributes, rights, landing, logged, loading, authenticating } =
		useAppSelector((state) => ({
			attributes: state.user.attributes,
			rights: state.rgt.rights,
			landing: state.lnd.landing,
			logged: state.user.logged,
			authenticating: state.user.loading,
			loading: state.lnd.loading,
		}));

	const hasAdminRights = rights.some((right) =>
		right.profile?.includes('admin')
	);

	const user: { [key: string]: string } = {
		...attributes?.reduce(
			(user, attr) => ({
				...user,
				...(attr.Name ? { [attr.Name]: attr.Value } : {}),
			}),
			{}
		),
	};

	const fontSize = '1.2rem';
	const height = '7vh';
	const backgroundImage = `url(${landing ? (landing?.bg_image ?? 'none') : '/img/pe/fake_brick.png'})`;
	const logoImage =
		landing?.horizontal ?? landing?.logo ?? '/img/pontodoesporte.svg';

	const profileOptions: (DropdownItemProps & ProfileOptions)[] = [
		{
			key: 'profile',
			as: Link,
			to: '/profile',
			selected: false,
			icon: 'settings',
			content: user?.name,
			show: ['widescreen', 'desktop', 'tablet', 'mobile'],
		},
		{
			key: 'dashboard',
			as: Link,
			to: '/dashboard',
			selected: false,
			icon: 'dashboard',
			content: 'Meu painel',
			show: ['widescreen', 'desktop', 'tablet', 'mobile'],
		},
		{
			key: 'places',
			as: Link,
			to: '/organizations',
			selected: false,
			icon: 'map',
			content: 'Meus locais',
			show: ['widescreen', 'desktop', 'tablet'],
		},
		{
			key: 'events',
			as: Link,
			to: '/events',
			selected: false,
			icon: 'trophy',
			content: 'Meus eventos',
			show: ['widescreen', 'desktop', 'tablet'],
		},
		{
			key: 'leagues',
			as: Link,
			to: '/leagues',
			selected: false,
			icon: 'flag',
			content: 'Minhas ligas',
			show: ['widescreen', 'desktop', 'tablet'],
		},
		{
			key: 'sign-out',
			as: Link,
			to: '/logout',
			selected: false,
			icon: 'sign out',
			content: 'Sair',
			show: ['widescreen', 'desktop', 'tablet', 'mobile'],
		},
	];

	const headerMenuItems = landing?.links || [
		{
			name: 'main',
			label: 'Quem somos',
			mobile: false,
		},
		{
			name: 'porque',
			label: 'Escolha',
			mobile: false,
		},
		{
			name: 'processo',
			label: 'Benefícios',
			mobile: false,
		},
		{
			name: 'mudanca',
			label: 'Mude agora',
			mobile: false,
		},
	];

	const handleSidebar = () => {
		if (openSidebar) handleCloseSidebar();
		else handleOpenSidebar();
	};

	return (
		<>
			<Menu
				id="topmenu"
				fixed={screenSize !== 'mobile' ? 'top' : undefined}
				inverted={loading || !landing || !!landing?.bg_color}
				borderless
				color={!landing?.bg_color ? undefined : landing.bg_color}
				style={{
					backgroundImage,
					borderRadius: 0,
					height,
					margin: 0,
				}}
			>
				{logged && screenSize !== 'mobile' && hasAdminRights && (
					<Menu.Item
						icon="sidebar"
						style={{
							width: '60px',
						}}
						onClick={handleSidebar}
					/>
				)}
				{loading ? (
					<Placeholder style={{ height }}>
						<Placeholder.Image />
					</Placeholder>
				) : (
					<Menu.Item as="a" header href={site} style={{ maxWidth: '50vw' }}>
						<Image src={logoImage} style={{ maxHeight: '100%' }} />
					</Menu.Item>
				)}

				<Menu.Menu position="right">
					{headerMenuItems.map(
						(item) =>
							(screenSize !== 'mobile' || item.mobile) && (
								<Menu.Item
									{...(item.link
										? { as: 'a', href: item.link }
										: {
												onClick: () => {
													history.push('/');
													handleMenuClick(item.name as Item);
												},
											})}
									key={item.name}
									name={item.name}
									active={activeItem === item.name}
									style={{ fontSize }}
								>
									{item.label}
								</Menu.Item>
							)
					)}

					{!landing &&
						(authenticating ? (
							<Menu.Item name="auth" style={{ fontSize }}>
								<Icon name="spinner" loading />
							</Menu.Item>
						) : logged ? (
							<Menu.Item name="user" style={{ fontSize }}>
								<Dropdown
									trigger={
										user.picture ? (
											<Image avatar src={user.picture} />
										) : user.gender ? (
											<Image
												avatar
												src={`/img/avatar/sports-${user.gender}.svg`}
											/>
										) : (
											<Icon name="user" size="large" />
										)
									}
									icon={null}
									pointing="top right"
									options={profileOptions.filter(
										(opt) => !!screenSize && opt.show.includes(screenSize)
									)}
								/>
							</Menu.Item>
						) : (
							<Menu.Item
								name="login"
								icon
								style={{ fontSize }}
								onClick={() => history.push('/login')}
							>
								{screenSize === 'mobile' ? (
									<Icon name={logged ? 'sign out' : 'sign in'} />
								) : (
									'Entrar'
								)}
							</Menu.Item>
						))}
				</Menu.Menu>
			</Menu>
			{/* Segment para manter os componentes visíveis abaixo do header */}
			{screenSize !== 'mobile' && <Segment basic vertical style={{ height }} />}
		</>
	);
};

export default Header;
