import { RightItem } from '../store/rights';

export type PlaceViewType = 'courts' | 'weeks' | 'directory';
export type EventViewType = 'draws' | 'registrations' | 'schedule';
export type LeagueViewType = 'ranking' | 'challenges';

export type ViewRoutes = {
	[route in PlaceViewType | EventViewType | LeagueViewType]?: AppRoute;
};

type InstitucionalType = { institucional?: boolean };
type ShowMenu = { menu?: boolean };
type ViewTypes = { views?: ViewRoutes };

type Responsive = {
	mobile: boolean;
	desktop: boolean;
};

export type AppRoute = Pick<
	RightItem,
	'id' | 'name' | 'icon' | 'redirect' | 'link' | 'super' | 'profile'
> &
	Responsive &
	ViewTypes &
	ShowMenu &
	InstitucionalType;

export type RouteType =
	| 'home'
	| 'terms'
	| 'policy'
	| 'about'
	| 'contact'
	| 'dashboard'
	| 'places'
	| 'championship'
	| 'classification'
	| 'occasion';

export type AppRoutes = {
	[route in RouteType]: AppRoute;
};

export const routes: AppRoutes = {
	home: {
		id: 'home',
		name: 'Home',
		redirect: false,
		link: '/',
		super: 'top',
		mobile: false,
		desktop: false,
		menu: false,
		institucional: true,
	},
	terms: {
		id: 'terms',
		name: 'Termos de uso',
		redirect: false,
		link: '/terms',
		super: 'top',
		mobile: false,
		desktop: false,
		menu: false,
		institucional: true,
	},
	policy: {
		id: 'policy',
		name: 'Política de privacidade',
		redirect: false,
		link: '/policy',
		super: 'top',
		mobile: false,
		desktop: false,
		menu: false,
		institucional: true,
	},
	about: {
		id: 'about',
		name: 'Sobre nós',
		redirect: false,
		link: '/about',
		super: 'top',
		mobile: false,
		desktop: false,
		menu: false,
		institucional: true,
	},
	contact: {
		id: 'contact',
		name: 'Contato',
		redirect: false,
		link: '/contact',
		super: 'top',
		mobile: false,
		desktop: false,
		menu: false,
		institucional: true,
	},
	dashboard: {
		id: 'dashboard',
		name: 'Painel',
		icon: 'dashboard',
		redirect: true,
		link: '/dashboard',
		super: 'top',
		mobile: true,
		desktop: true,
		menu: true,
		institucional: false,
	},
	places: {
		id: 'places',
		name: 'Meus locais',
		icon: 'pin',
		redirect: false,
		link: '/places',
		super: 'top',
		mobile: false,
		desktop: true,
		menu: true,
		institucional: false,
		views: {
			courts: {
				id: 'courts',
				name: 'Quadras',
				icon: 'map marker alternate',
				super: 'places',
				mobile: true,
				desktop: true,
				profile: ['admin'],
			},
			directory: {
				id: 'directory',
				name: 'Cadastro',
				icon: 'address book outline',
				super: 'places',
				mobile: false,
				desktop: true,
				profile: ['admin'],
			},
			weeks: {
				id: 'weeks',
				name: 'Semanas',
				icon: 'calendar alternate',
				super: 'places',
				mobile: false,
				desktop: false,
				profile: ['admin'],
			},
		},
	},
	championship: {
		id: 'events',
		name: 'Meus eventos',
		icon: 'trophy',
		redirect: false,
		link: '/championship',
		super: 'top',
		mobile: true,
		desktop: true,
		menu: true,
		institucional: false,
		views: {
			registrations: {
				id: 'registrations',
				name: 'Inscrições',
				icon: 'users',
				super: 'events',
				mobile: true,
				desktop: true,
				profile: ['admin', 'player'],
			},
			draws: {
				id: 'draws',
				name: 'Chaves',
				icon: 'table',
				super: 'events',
				mobile: true,
				desktop: true,
				profile: ['admin', 'player', 'viewer'],
			},
			schedule: {
				id: 'schedule',
				name: 'Chamadas',
				icon: 'clock',
				super: 'events',
				mobile: true,
				desktop: true,
				profile: ['admin'],
			},
		},
	},
	classification: {
		id: 'leagues',
		name: 'Minhas ligas',
		icon: 'flag',
		redirect: false,
		link: '/classification',
		super: 'top',
		mobile: true,
		desktop: true,
		menu: true,
		institucional: false,
		views: {
			ranking: {
				id: 'ranking',
				name: 'Ranking',
				icon: 'ordered list',
				super: 'leagues',
				mobile: true,
				desktop: true,
				profile: ['admin', 'player'],
			},
			challenges: {
				id: 'challenges',
				name: 'Desafios',
				icon: 'handshake',
				super: 'leagues',
				mobile: true,
				desktop: true,
				profile: ['admin', 'player'],
			},
		},
	},
	occasion: {
		id: 'occasion',
		name: 'Evento',
		desktop: false,
		mobile: false,
		icon: 'trophy',
		redirect: false,
		link: '/occasion',
		institucional: false,
		super: 'top',
	},
};
