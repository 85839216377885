import React, { useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Card, Header, Icon, Image, Statistic } from 'semantic-ui-react';

import Counter from './counter';
import { ScreenSizeContext } from '../../contexts/ScreenContext';
import { useAppDispatch } from '../../store/hooks';
import { RightItem } from '../../store/rights';
import { getStat, StatisticItem } from '../../store/statistics';

interface iUnit {
	unit: RightItem;
	stat?: StatisticItem;
}

const Unit: React.FC<iUnit> = ({ unit, stat }) => {
	const dispatch = useAppDispatch();
	const screenSize = useContext(ScreenSizeContext);

	useEffect(() => {
		dispatch(getStat({ id: unit.id, type: 'unit' }));
	}, [dispatch, unit.id]);

	return (
		<Card
			as={Link}
			to={`/places/${unit.id}`}
			centered={screenSize === 'mobile'}
			fluid={screenSize === 'mobile'}
		>
			<Card.Content>
				<Header size={screenSize === 'mobile' ? 'small' : 'medium'}>
					<Image src={unit.img} size="mini" floated="right" />
					{screenSize === 'mobile' && unit.nick ? unit.nick : unit?.name}
				</Header>
			</Card.Content>
			{stat?.loading ? (
				<Card.Content textAlign="center">
					<Icon name="spinner" loading size="big" color="black" />
				</Card.Content>
			) : (
				<Card.Content textAlign="center">
					{stat?.stats?.map((stat) => {
						switch (stat.type) {
							case 'count':
								return (
									<Statistic
										key={stat.id}
										size={screenSize === 'mobile' ? 'mini' : undefined}
									>
										<Statistic.Label>{stat.name}</Statistic.Label>
										<Statistic.Value>
											<Counter
												initial={0}
												target={+(stat.value ?? 0)}
												easing="quadratic"
											/>
										</Statistic.Value>
									</Statistic>
								);
							case 'date':
								return (
									<Statistic
										key={stat.id}
										label={stat.name}
										value={new Date(stat.value).toLocaleDateString()}
										size="mini"
									/>
								);
							default:
								return null;
						}
					})}
				</Card.Content>
			)}
		</Card>
	);
};

export default Unit;
