import React, { useContext, useEffect } from 'react';

import {
	Header,
	Image,
	Card,
	Grid,
	Loader,
	Segment,
	Icon,
} from 'semantic-ui-react';

import { ScreenSizeContext } from '../../contexts/ScreenContext';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RightItem } from '../../store/rights';
import { resetStats } from '../../store/statistics';
import Rank from '../dashboard/rank';
import ErrorBoundary from '../errors/boundary';

interface iClass {
	perms?: RightItem[];
}

const ClassIndex: React.FC<iClass> = ({ perms }) => {
	const dispatch = useAppDispatch();
	const screenSize = useContext(ScreenSizeContext);

	const { rights, stats, loading } = useAppSelector((state) => ({
		rights: state.rgt.rights,
		stats: state.stt.stats,
		loading: state.rgt.loading,
	}));

	const leagues = (perms ?? []).reduce((leagues, rank) => {
		const league = rights.find((r) => rank.super === r.id);

		return [
			...leagues,
			...(league && !leagues.some((p) => p.id === league.id) ? [league] : []),
		];
	}, [] as RightItem[]);

	const ranks = leagues?.reduce(
		(ranks, league) => ({
			...ranks,
			[league.id]: perms?.filter((p) => p.super === league.id) as RightItem[],
		}),
		{} as { [key: string]: RightItem[] }
	);

	useEffect(
		() => () => {
			dispatch(resetStats());
		},
		[dispatch]
	);

	return (
		<ErrorBoundary>
			<Loader active={loading} />
			{!leagues.length && !loading && (
				<Segment basic placeholder style={{ margin: '2rem' }}>
					<Header icon>
						<Icon name="flag" />
						Você não está inscrito em nenhum ranking.
					</Header>
				</Segment>
			)}
			{leagues.map((league) => (
				<Grid key={league?.id} padded>
					<Grid.Row>
						<Grid.Column>
							<Header dividing>{league?.name}</Header>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						{league.img && (
							<Grid.Column width={screenSize === 'mobile' ? 6 : 4}>
								<Image src={league.img} />
							</Grid.Column>
						)}
						<Grid.Column width={league.img ? 12 : 16}>
							<Card.Group itemsPerRow={screenSize === 'mobile' ? 2 : undefined}>
								{ranks[league.id]?.map((rank) => (
									<Rank
										key={rank.id}
										rank={rank}
										stat={stats?.find((s) => s.id === rank.id)}
									/>
								))}
							</Card.Group>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			))}
		</ErrorBoundary>
	);
};

export default ClassIndex;
