import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
	formatPhoneNumberIntl,
	isPossiblePhoneNumber,
	parsePhoneNumber,
	Value as PhoneNumberValue,
} from 'react-phone-number-input';
import {
	Button,
	Form,
	Grid,
	Header,
	Image,
	Message,
	Segment,
	InputOnChangeData,
	CheckboxProps,
	DropdownProps,
	Modal,
} from 'semantic-ui-react';
import { useHeadSafe } from 'unhead';

import VerifyCode from './validation';
import { ScreenSizeContext } from '../../contexts/ScreenContext';
import { authSignUp } from '../../store/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { countryOptions } from '../../util/places/countries';
import checkValidity from '../../util/valida/check';
import ErrorBoundary from '../errors/boundary';
import Policy from '../home/policy/policy';
import Terms from '../home/terms/terms';

export interface SignUpProps {
	name: string;
	mail: string;
	phone: string;
	password: string;
	confirm: string;
}

const SignUpForm: React.FC = () => {
	useHeadSafe({
		templateParams: {
			site: {
				name: 'Ponto do Esporte',
				url: 'https://www.pontodoesporte.com.br',
			},
			separator: '|',
		},
		title: 'Cadastro',
		titleTemplate: '%s %separator %site.name',
		meta: [
			{
				name: 'description',
				content: '%s do %site.name.',
			},
			{
				property: 'og:site_name',
				content: '%site.name',
			},
			{
				property: 'og:url',
				content: '%site.url/signup',
			},
		],
	});

	const dispatch = useAppDispatch();

	const screenSize = useContext(ScreenSizeContext);

	const { loading, error } = useAppSelector((state) => ({
		loading: state.user.loading,
		error: state.user.error,
	}));

	const [profile, setProfile] = useState<SignUpProps>({
		name: '',
		mail: '',
		phone: '',
		password: '',
		confirm: '',
	});
	const { name, mail, phone, password, confirm } = profile;

	const [country, setCountry] = useState<string>();

	const [policy, setPolicy] = useState(false);

	const [validate, setValidate] = useState(false);

	const [errors, setErrors] = useState<string[]>([
		'name',
		'mail',
		'phone',
		'password',
		'confirm',
		'policy',
	]);

	const countrySign = window.navigator.language.split('-')?.[1].toLowerCase();

	const [showDocument, setShowDocument] = useState<'policy' | 'terms'>();

	const findCountryBySign = (sign?: string) =>
		countryOptions.find(
			(country) => country.value.toLowerCase() === sign?.toLowerCase()
		);

	useEffect(() => {
		setCountry(countrySign);
	}, [countrySign]);

	useEffect(() => {
		if (country) {
			const countryCode = findCountryBySign(country ?? 'br')?.code;
			setProfile((prevProfile) => ({
				...prevProfile,
				phone: `+${countryCode}`,
			}));
		}
	}, [country]);

	const handleSetError = (field: string, error: boolean) => {
		setErrors((prevErrors) => [
			...prevErrors.filter((error) => error !== field),
			...(error ? [field] : []),
		]);
	};

	const handleValidation = useCallback(
		(field: string, value?: string | boolean) => {
			switch (field) {
				case 'name':
					handleSetError(
						field,
						typeof value !== 'string' || checkValidity(field, value)
					);
					break;
				case 'phone':
					handleSetError(
						field,
						typeof value !== 'string' ||
							checkValidity(field, value) ||
							isPossiblePhoneNumber(value.replace('+', ''))
					);
					break;
				case 'mail':
					handleSetError(
						field,
						typeof value !== 'string' || checkValidity(field, value)
					);
					break;
				case 'password':
					handleSetError(
						field,
						typeof value !== 'string' || checkValidity(field, value)
					);
					break;
				case 'confirm':
					handleSetError(field, value !== password);
					break;
				case 'policy':
					handleSetError(field, !value);
					break;
				default:
					handleSetError(field, false);
					break;
			}
		},
		[password]
	);

	useEffect(() => {
		Object.entries(profile).forEach((attr) =>
			handleValidation(attr[0], attr[1])
		);
	}, [handleValidation, profile]);

	useEffect(() => {
		handleValidation('policy', policy);
	}, [handleValidation, policy]);

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		data: InputOnChangeData
	) => {
		const { field, value } = data;

		setProfile((prevProfile) => ({
			...prevProfile,
			[field]: value,
		}));
	};

	const handleCountry = (
		event: React.SyntheticEvent<HTMLElement, Event>,
		data: DropdownProps
	) => {
		const country = findCountryBySign(`${data.value}`);
		setCountry(`${data.value}`);
		setProfile((prevProfile) => ({
			...prevProfile,
			phone: `+${country?.code}`,
		}));
	};

	const handlePhone = (
		event: React.ChangeEvent<HTMLInputElement>,
		data: InputOnChangeData
	) => {
		const parsedValue =
			parsePhoneNumber(data.value)?.number?.replace('+', '') ||
			data.value.replace('+', '');

		setProfile((prevProfile) => ({
			...prevProfile,
			phone: `+${parsedValue}`,
		}));
	};

	const handlePolicy = (
		event: React.FormEvent<HTMLInputElement>,
		data: CheckboxProps
	) => {
		setPolicy(!!data.checked);
	};

	const handleSubmit = () => {
		setValidate(true);

		if (errors.length === 0) {
			dispatch(
				authSignUp({
					Name: name,
					Username: mail,
					Phone: phone,
					Password: password,
				})
			);
		}
	};

	return (
		<Segment
			id="signup"
			basic
			style={{
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'center',
				minHeight: `calc(100vh - ${screenSize === 'mobile' ? '15vh' : '13vh'})`,
			}}
		>
			<ErrorBoundary>
				<Grid
					textAlign="center"
					verticalAlign="middle"
					style={{
						...(screenSize !== 'mobile'
							? { maxWidth: '450px', minWidth: '450px' }
							: { width: '100%' }),
					}}
				>
					<Grid.Column>
						<Header as="h2" color="black" textAlign="center">
							<Image src="\img\pe.png" /> Crie sua conta
						</Header>
						<Form size="large" loading={loading}>
							<Segment stacked>
								<Form.Input
									field="name"
									value={name}
									error={errors.includes('name') && validate}
									fluid
									icon="user"
									iconPosition="left"
									placeholder="Nome completo"
									onChange={handleChange}
								/>
								<Message
									negative
									size="mini"
									hidden={!(errors.includes('name') && validate)}
								>
									Por favor, preencha com o seu nome completo.
								</Message>
								<Form.Group>
									<Form.Dropdown
										value={country}
										field="country"
										basic
										button
										fluid
										search
										width={5}
										options={countryOptions.map(
											(country: {
												code: string;
												value: string;
												en_US: string;
												pt_BR: string;
												continent: string;
											}) => ({
												key: country.value,
												value: country.value,
												flag: country.value,
												content: country.pt_BR,
											})
										)}
										onChange={handleCountry}
									/>
									<Form.Input
										value={
											formatPhoneNumberIntl(phone as PhoneNumberValue) || phone
										}
										field="phone"
										placeholder="Celular"
										fluid
										width={11}
										onChange={handlePhone}
									/>
								</Form.Group>
								<Message
									negative
									size="mini"
									hidden={!(errors.includes('phone') && validate)}
								>
									Por favor, preencha corretamente seu telefone celular.
								</Message>
								<Form.Input
									field="mail"
									value={mail}
									error={errors.includes('mail') && validate}
									fluid
									icon="mail"
									iconPosition="left"
									placeholder="E-mail"
									onChange={handleChange}
								/>
								<Message
									negative
									size="mini"
									hidden={!(errors.includes('mail') && validate)}
								>
									Por favor, preencha um e-mail válido.
								</Message>
								<Form.Input
									field="password"
									value={password}
									error={errors.includes('password') && validate}
									fluid
									icon="lock"
									iconPosition="left"
									placeholder="Senha"
									type="password"
									onChange={handleChange}
								/>
								<Message
									negative
									size="mini"
									hidden={!(errors.includes('password') && validate)}
								>
									<Message.Header>Senha deve conter no mínimo:</Message.Header>
									<Message.List>
										<Message.Item>1 letra minúscula</Message.Item>
										<Message.Item>1 letra maiúscula</Message.Item>
										<Message.Item>1 número</Message.Item>
										<Message.Item>1 caracter especial</Message.Item>
										<Message.Item>8 caracteres</Message.Item>
									</Message.List>
								</Message>
								<Form.Input
									field="confirm"
									value={confirm}
									error={errors.includes('confirm') && validate}
									fluid
									icon="lock"
									iconPosition="left"
									placeholder="Confirmação"
									type="password"
									onChange={handleChange}
								/>
								<Message
									negative
									size="mini"
									hidden={!(errors.includes('confirm') && validate)}
								>
									A confirmação de senha não é igual à senha digitada.
								</Message>
								<Button
									icon="sign in"
									content="Cadastrar"
									positive
									fluid
									size="medium"
									onClick={handleSubmit}
								/>
							</Segment>
						</Form>
						{error ? (
							<Message error>{error.message}</Message>
						) : (
							<Message error={errors.includes('policy') && validate}>
								<Form.Checkbox
									name="policy"
									label="Ao se inscrever você concorda com nossa "
									checked={policy}
									error={errors.includes('policy') && validate}
									onChange={handlePolicy}
								/>
								<a onClick={() => setShowDocument('policy')}>
									Política de Privacidade
								</a>{' '}
								e <a onClick={() => setShowDocument('terms')}>Termos de Uso</a>
							</Message>
						)}
					</Grid.Column>
				</Grid>
				<Modal
					open={!!showDocument}
					closeIcon
					size="fullscreen"
					onClose={() => setShowDocument(undefined)}
				>
					<Modal.Header>
						{showDocument === 'policy'
							? 'Política de Privacidade'
							: 'Termos de Uso'}
					</Modal.Header>
					<Modal.Content scrolling>
						{showDocument === 'policy' && <Policy />}
						{showDocument === 'terms' && <Terms />}
					</Modal.Content>
				</Modal>
			</ErrorBoundary>
			<VerifyCode Username={mail} Password={password} />
		</Segment>
	);
};

export default SignUpForm;
