import React, { useContext } from 'react';

import { Segment } from 'semantic-ui-react';
import { useHeadSafe } from 'unhead';

import Main from './main';
import Mudanca from './mudanca';
import Porque from './porque';
import Processo from './processo';
import { Item } from '../../app';
import { ScreenSizeContext } from '../../contexts/ScreenContext';
import { useScroll } from '../../hooks';
import ErrorBoundary from '../errors/boundary';

export type HomeProps = {
	activeItem: Item;
};

const Home: React.FC<HomeProps> = ({ activeItem }) => {
	const screenSize = useContext(ScreenSizeContext);

	useHeadSafe({
		templateParams: {
			site: {
				name: 'Ponto do Esporte',
				url: 'https://www.pontodoesporte.com.br',
			},
		},
		titleTemplate: '%site.name',
		link: [
			{
				rel: 'shortcut icon',
				href: '/img/pe.png',
			},
		],
		meta: [
			{
				name: 'description',
				content: 'A plataforma para gestão de eventos e ligas esportivas',
			},
			{
				property: 'og:url',
				content: 'https://www.pontodoesporte.com.br',
			},
			{
				property: 'og:type',
				content: 'website',
			},
			{
				property: 'og:locale',
				content: 'pt_BR',
			},
		],
	});

	useScroll(activeItem ?? 'home');

	return (
		<Segment
			id="home"
			basic
			style={{
				padding: 0,
				fontSize: screenSize !== 'mobile' ? '1.5rem' : '1.2rem',
			}}
		>
			<ErrorBoundary>
				<Main />
				<Porque />
				<Processo />
				<Mudanca />
			</ErrorBoundary>
		</Segment>
	);
};

export default Home;
