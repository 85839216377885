import React, { useContext } from 'react';

import { useHistory } from 'react-router';
import { Button, Grid, Header, Image, Segment } from 'semantic-ui-react';

import { ScreenSizeContext } from '../../contexts/ScreenContext';

const Main = () => {
	const screenSize = useContext(ScreenSizeContext);
	const history = useHistory();

	return (
		<Segment id="main" basic>
			<Grid stackable container centered style={{ height: '70vh' }}>
				<Grid.Column computer={5} textAlign="right" verticalAlign="middle">
					<Image src="img/pontodoesporte.jpg" centered size="medium" />
				</Grid.Column>
				<Grid.Column computer={7} verticalAlign="middle">
					<Grid.Row textAlign="left" verticalAlign="bottom">
						<Header
							size={screenSize !== 'mobile' ? 'huge' : 'large'}
							textAlign="left"
							style={{ textTransform: 'uppercase' }}
						>
							Simplifique, Otimize e Engaje
							<br />
							com o Ponto do Esporte
						</Header>
					</Grid.Row>
					<Grid.Row>
						<p
							style={{
								textAlign: 'justify',
								fontSize: '1.5em',
							}}
						>
							Organizar e gerenciar torneios e eventos esportivos pode ser uma
							tarefa complexa e demorada, mas o Ponto do Esporte está aqui para
							mudar isso.
						</p>
					</Grid.Row>
					<Grid.Row verticalAlign="middle" textAlign="left">
						<Button
							size="large"
							compact
							style={{
								backgroundColor: 'rgba(169, 207, 70)',
								borderRadius: '15px',
								margin: '2em 0 0 0',
							}}
							onClick={() => history.push('/signup')}
						>
							CADASTRE-SE AGORA MESMO!
						</Button>
					</Grid.Row>
				</Grid.Column>
			</Grid>
		</Segment>
	);
};

export default Main;
