import React, { useContext, useEffect } from 'react';

import {
	Card,
	Grid,
	Header,
	Icon,
	Image,
	Loader,
	Segment,
} from 'semantic-ui-react';

import { ScreenSizeContext } from '../../contexts/ScreenContext';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { RightItem } from '../../store/rights';
import { resetStats } from '../../store/statistics';
import Tourn from '../dashboard/tourn';
import ErrorBoundary from '../errors/boundary';

interface iChamp {
	perms?: RightItem[];
}

const ChampIndex: React.FC<iChamp> = ({ perms }) => {
	const screenSize = useContext(ScreenSizeContext);
	const dispatch = useAppDispatch();

	const { rights, stats, loading } = useAppSelector((state) => ({
		rights: state.rgt.rights,
		stats: state.stt.stats,
		loading: state.rgt.loading,
	}));

	const events = (perms ?? []).reduce((events, tourn) => {
		const event = rights.find((r) => tourn.super === r.id);

		return [
			...events,
			...(event && !events.some((e) => e.id === event.id) ? [event] : []),
		];
	}, [] as RightItem[]);

	const tourns = events?.reduce(
		(tourns, event) => ({
			...tourns,
			[event.id]: perms?.filter((p) => p.super === event.id) as RightItem[],
		}),
		{} as { [key: string]: RightItem[] }
	);

	useEffect(
		() => () => {
			dispatch(resetStats());
		},
		[dispatch]
	);

	return (
		<ErrorBoundary>
			<Loader active={loading} />
			{!events.length && !loading && (
				<Segment basic placeholder style={{ margin: '2rem' }}>
					<Header icon>
						<Icon name="trophy" />
						Você não está inscrito em nenhuma competição.
					</Header>
				</Segment>
			)}
			{events.map((event) => (
				<Grid key={event?.id} padded stackable>
					<Grid.Row>
						<Grid.Column>
							<Header dividing>{`${event?.name} ${event?.nick}`}</Header>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						{event.img && (
							<Grid.Column
								textAlign="center"
								width={screenSize === 'mobile' ? 4 : 2}
							>
								<Image src={event.img} centered size="small" />
							</Grid.Column>
						)}
						<Grid.Column width={event.img ? 12 : 16}>
							<Card.Group itemsPerRow={screenSize === 'mobile' ? 2 : undefined}>
								{tourns[event.id]?.map((tourn) => (
									<Tourn
										key={tourn.id}
										tourn={tourn}
										stat={stats?.find((s) => s.id === tourn.id)}
									/>
								))}
							</Card.Group>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			))}
		</ErrorBoundary>
	);
};

export default ChampIndex;
