import React, { useContext } from 'react';

import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import { Sidebar } from 'semantic-ui-react';

import SidebarMenu from './leftmenu';
import BottomMenu from './mobilemenu';
import { ScreenSizeContext } from '../../contexts/ScreenContext';
import { AppRoutes } from '../../routers/routes';
import { useAppSelector } from '../../store/hooks';
import ErrorBoundary from '../errors/boundary';

interface BarProps {
	open: boolean;
	routes: AppRoutes;
	institucionalRoutes?: string[];
	children: React.ReactNode;
	closeSidebar: () => void;
	openSidebar: () => void;
}

const MenuBar: React.FC<BarProps> = ({
	open,
	routes,
	children,
	closeSidebar,
	openSidebar,
}) => {
	const { id } = useParams<{ id?: string }>();
	const { pathname } = useLocation();
	const screenSize = useContext(ScreenSizeContext);
	const { logged, rights } = useAppSelector((state) => ({
		logged: state.user.logged,
		rights: state.rgt.rights,
	}));

	const topMenuItems = Object.values(routes);

	const routeItem = topMenuItems.find((item) =>
		item.link === '/'
			? pathname === '/'
			: item.link && pathname.startsWith(item.link)
	);

	const showMenu = routeItem?.menu;

	const isInstitucional = routeItem?.institucional;

	const desktopMenuItems = topMenuItems.filter(
		(top) => top.desktop && !top.institucional
	);

	const mobileMenuItems = topMenuItems.filter(
		(top) =>
			top.mobile &&
			!top.institucional &&
			(!id || pathname.startsWith(top.link ?? ''))
	);

	const mobileViewItems =
		!routeItem || !id
			? []
			: Object.values(routeItem?.views ?? {})
					.map((item) => ({
						...item,
						link: `${pathname}?${queryString.stringify({ view: item.id })}`,
					}))
					.filter((item) => item.mobile);

	const hasAdminRights = rights.some((right) =>
		right.profile?.includes('admin')
	);

	return (
		<Sidebar.Pushable>
			{logged && (showMenu || open) && (
				<ErrorBoundary>
					{screenSize !== 'mobile' && hasAdminRights && (
						<SidebarMenu
							open={open}
							topMenuItems={desktopMenuItems}
							openSidebar={openSidebar}
							closeSidebar={closeSidebar}
						>
							{children}
						</SidebarMenu>
					)}
				</ErrorBoundary>
			)}
			<Sidebar.Pusher
				style={
					screenSize !== 'mobile'
						? {
								minHeight: `${isInstitucional ? '80vh' : '88vh'}`,
							}
						: logged && !isInstitucional
							? {
									minHeight: '93vh',
									maxHeight: '93vh',
									overflowY: 'auto',
									paddingBottom: '7vh',
								}
							: {
									minHeight: 'calc(100vh - 12vh)',
								}
				}
			>
				{children}
				{screenSize === 'mobile' && logged && !isInstitucional && (
					<BottomMenu
						bottomMenuItems={[...mobileMenuItems, ...mobileViewItems]}
					/>
				)}
			</Sidebar.Pusher>
		</Sidebar.Pushable>
	);
};

export default MenuBar;
