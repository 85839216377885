import React, { useContext, useEffect, useState } from 'react';

import { Value } from 'react-phone-number-input';
import {
	Segment,
	Grid,
	Header,
	Image,
	Menu,
	Icon,
	Label,
} from 'semantic-ui-react';
import { useHeadSafe } from 'unhead';

import Attributes from './attributes';
import Security from './security';
import { ScreenSizeContext } from '../../contexts/ScreenContext';
import { useAppSelector } from '../../store/hooks';
import checkValidity from '../../util/valida/check';
import ErrorBoundary from '../errors/boundary';
import ImageModal from '../image/edit';
import VerifyCode from '../login/validation';

type Attribute = {
	value: string;
	changed?: boolean;
	error?: boolean;
};

type PhoneAttribute = {
	value: Value;
	changed?: boolean;
	error?: boolean;
};

export type UserProps = {
	sub?: Attribute;
	name?: Attribute;
	email?: Attribute;
	phone_number?: PhoneAttribute;
	birthdate?: Attribute;
	nickname?: Attribute;
	gender?: Attribute;
	picture?: Attribute;
};

const Profile: React.FC = () => {
	const screenSize = useContext(ScreenSizeContext);

	const [viewMode, setViewMode] = useState<'ATTRIBUTES' | 'SECURITY'>(
		'ATTRIBUTES'
	);

	const { attributes, loading } = useAppSelector((state) => ({
		attributes: state.user.attributes,
		loading: state.user.loading,
	}));

	const [profile, setProfile] = useState<UserProps>({
		name: { value: '' },
		email: { value: '' },
		phone_number: { value: '' as Value },
		birthdate: { value: '' },
		nickname: { value: '' },
		gender: { value: '' },
		picture: { value: '' },
	});

	const [editImage, setEditImage] = useState(false);

	const handleImage = (image: string) => {
		setProfile((state) => ({
			...state,
			picture: {
				value: image,
				changed: true,
				error: !image,
			},
		}));
	};

	useHeadSafe({
		templateParams: {
			site: {
				name: 'Ponto do Esporte',
				url: 'https://www.pontodoesporte.com.br',
			},
			separator: '|',
		},
		title: profile.name?.value ?? 'Perfil',
		titleTemplate: '%s %separator %site.name',
		meta: [
			{
				name: 'description',
				content: '%s no %site.name.',
			},
			{
				property: 'og:site_name',
				content: '%site.name',
			},
			{
				property: 'og:url',
				content: '%site.url/profile',
			},
		],
	});

	useEffect(() => {
		attributes?.forEach((attr) => {
			setProfile((profile) => ({
				...profile,
				...(attr.Name
					? {
							[attr.Name]: {
								value: attr.Value,
								changed: false,
								error: checkValidity(attr.Name, attr.Value ?? ''),
							},
						}
					: {}),
			}));
		});
	}, [attributes]);

	return (
		<ErrorBoundary>
			<Grid
				as={Segment}
				id="profile"
				basic
				columns={2}
				divided
				padded
				stackable
			>
				<Grid.Column width={4} textAlign="center">
					<Segment basic loading={loading && profile.picture?.changed}>
						<Label corner icon="camera" onClick={() => setEditImage(true)} />
						{profile.picture?.value ? (
							<Image
								id="profile_image"
								src={profile.picture.value}
								rounded
								centered
								size="medium"
							/>
						) : profile.gender?.value ? (
							<Image
								id="profile_image"
								src={`/img/avatar/sports-${profile.gender.value}.svg`}
								rounded
								centered
								size="medium"
							/>
						) : (
							<Icon
								name="user"
								size="massive"
								onClick={() => setEditImage(true)}
							/>
						)}
					</Segment>
					<Header size="medium">
						{profile.name?.value}
						<Header.Subheader>( {profile.email?.value} )</Header.Subheader>
					</Header>
				</Grid.Column>

				<Grid.Column width={12}>
					<Menu pointing secondary>
						<Menu.Item
							active={viewMode === 'ATTRIBUTES'}
							content="Dados do usuário"
							onClick={() => setViewMode('ATTRIBUTES')}
						/>
						<Menu.Menu position="right">
							<Menu.Item
								active={viewMode === 'SECURITY'}
								content="Segurança"
								onClick={() => setViewMode('SECURITY')}
							/>
						</Menu.Menu>
					</Menu>

					{viewMode === 'ATTRIBUTES' && (
						<Attributes
							profile={profile}
							loading={loading}
							setProfile={setProfile}
						/>
					)}

					{viewMode === 'SECURITY' && <Security loading={loading} />}
				</Grid.Column>

				{screenSize !== 'mobile' && (
					<ImageModal
						show={editImage}
						image={profile.picture?.value}
						domain="users"
						entity={profile.sub?.value ?? 'sub'}
						type="picture"
						size={300}
						aspect={1}
						closeImage={() => setEditImage(false)}
						changeImage={handleImage}
					/>
				)}
			</Grid>
			<VerifyCode />
		</ErrorBoundary>
	);
};

export default Profile;
