import React, { useEffect, useState } from 'react';

import { BrowserRouter } from 'react-router-dom';

import Footer from './components/footer';
import Loader from './components/loader';
import Header from './components/menus/header';
import { ScreenSizeProvider } from './contexts/ScreenProvider';
import { useDomain } from './hooks';
import Router from './routers/router';
import { routes } from './routers/routes';
import { authLogin } from './store/auth';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { getLanding, resetLanding } from './store/landings';

export type Item = 'app' | 'porque' | 'processo' | 'mudanca' | 'user' | 'login';

const institucionalRoutes = Object.values(routes).reduce(
	(routes, route) => [
		...routes,
		...(route.institucional && !!route.link ? [route.link] : []),
	],
	[] as string[]
);

const App: React.FC = () => {
	const { sub } = useDomain();
	const dispatch = useAppDispatch();

	const { logged, loading } = useAppSelector((state) => ({
		logged: state.user.logged,
		loading:
			state.plc.loading ||
			state.cmp.loading ||
			state.cls.loading ||
			state.lnd.loading,
	}));

	const RefreshToken =
		sessionStorage.getItem('RefreshToken') ||
		localStorage.getItem('RefreshToken');

	const [activeItem, setActiveItem] = useState<Item>('app');

	const [sidebar, setSidebar] = useState<boolean>(false);

	const isLanding = sub && sub !== 'hom' && sub !== 'www';

	const handleMenuClick = (name: Item) => {
		setActiveItem(name);
	};

	const handleOpenSidebar = () => {
		setSidebar(true);
	};

	const handleCloseSidebar = () => {
		setSidebar(false);
	};

	useEffect(() => {
		if (!logged) setSidebar(false);
	}, [logged]);

	useEffect(() => {
		if (!logged && RefreshToken) {
			dispatch(authLogin({ RefreshToken }));
		}
	}, [RefreshToken, logged, dispatch]);

	useEffect(() => {
		if (isLanding) {
			console.info(`Iniciando landing page ${sub}`);
			dispatch(getLanding({ id: sub }));
		} else {
			dispatch(resetLanding());
		}
	}, [dispatch, isLanding, sub]);

	return (
		<ScreenSizeProvider>
			<BrowserRouter>
				<Header
					activeItem={activeItem}
					openSidebar={sidebar}
					handleMenuClick={handleMenuClick}
					handleOpenSidebar={handleOpenSidebar}
					handleCloseSidebar={handleCloseSidebar}
				/>
				<Router
					routes={routes}
					activeItem={activeItem}
					openSidebar={sidebar}
					institucionalRoutes={institucionalRoutes}
					handleOpenSidebar={handleOpenSidebar}
					handleCloseSidebar={handleCloseSidebar}
				/>
				<Footer institucionalRoutes={institucionalRoutes} />
				<Loader open={loading} images={['/img/pe/loading.gif']} />
			</BrowserRouter>
		</ScreenSizeProvider>
	);
};

export default App;
