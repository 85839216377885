import React, { useContext } from 'react';

import { Grid, Header, Segment } from 'semantic-ui-react';

import { ScreenSizeContext } from '../../contexts/ScreenContext';

const Processo = () => {
	const screenSize = useContext(ScreenSizeContext);

	return (
		<Segment
			id="processo"
			basic
			style={{
				padding: '100px 0px',
			}}
		>
			<Grid stackable container>
				<Grid.Row>
					<Grid.Column width={11} style={{ padding: 0 }}>
						<Segment
							style={{
								border: '1px solid rgba(169, 207, 70)',
								...(screenSize === 'mobile'
									? {
											borderRadius: '15px',
										}
									: {
											borderRadius: '15px 0 15px 15px',
										}),
							}}
						>
							<Segment
								compact
								style={{
									backgroundColor: 'rgba(87, 99, 55, 0.4)',
								}}
							>
								<Header
									size="small"
									style={{
										fontWeight: 'bolder',
										textTransform: 'uppercase',
									}}
								>
									Processoo de Inscrições Simplificado
								</Header>
								<p
									style={{
										fontSize: '1.1em',
										lineHeight: '1.7em',
									}}
								>
									Com inscrição online para atletas e equipes com confirmação
									automática de inscrição via email.
								</p>
							</Segment>
							<Segment
								compact
								style={{
									backgroundColor: 'rgba(87, 99, 55, 0.4)',
								}}
							>
								<Header
									size="small"
									style={{
										fontWeight: 'bolder',
										textTransform: 'uppercase',
									}}
								>
									Formatação de Chaves e Tabelas
								</Header>
								<p
									style={{
										fontSize: '1.1em',
										lineHeight: '1.7em',
									}}
								>
									Configuração personalizada para diferentes tipos de torneios
									(simples, duplas, eliminatórias, round-robin).
								</p>
							</Segment>
							<Segment
								compact
								style={{
									backgroundColor: 'rgba(87, 99, 55, 0.4)',
								}}
							>
								<Header
									size="small"
									style={{
										fontWeight: 'bolder',
										textTransform: 'uppercase',
									}}
								>
									Marcação de Jogos e Chamadas
								</Header>
								<p
									style={{
										fontSize: '1.1em',
										lineHeight: '1.7em',
									}}
								>
									Agendamento automático dos jogos e sistema de chamadas para o
									próximo jogo de maneira fácil e intuitiva.
								</p>
							</Segment>
							<Segment
								compact
								style={{
									backgroundColor: 'rgba(87, 99, 55, 0.4)',
								}}
							>
								<Header
									size="small"
									style={{
										fontWeight: 'bolder',
										textTransform: 'uppercase',
									}}
								>
									Acompanhamento em Tempo Real
								</Header>
								<p
									style={{
										fontSize: '1.1em',
										lineHeight: '1.7em',
									}}
								>
									Atualização automática das tabelas e classificações com
									publicação de resultados instantâneos.
								</p>
							</Segment>
							<Segment
								compact
								style={{
									backgroundColor: 'rgba(87, 99, 55, 0.4)',
								}}
							>
								<Header
									size="small"
									style={{
										fontWeight: 'bolder',
										textTransform: 'uppercase',
									}}
								>
									Gestão de Pontuação e Rankings
								</Header>
								<p
									style={{
										fontSize: '1.1em',
										lineHeight: '1.7em',
									}}
								>
									Entrada e validação de resultados e cálculo automático de
									pontos e atualização de rankings.
								</p>
							</Segment>
						</Segment>
					</Grid.Column>
					<Grid.Column width={5} style={{ padding: 0 }}>
						<Segment
							style={{
								borderTop: '1px solid rgba(169, 207, 70)',
								borderRight: '1px solid rgba(169, 207, 70)',
								borderBottom: '1px solid rgba(169, 207, 70)',
								...(screenSize === 'mobile'
									? {
											borderLeft: '1px solid rgba(169, 207, 70)',
											borderRadius: '15px',
										}
									: {
											borderLeft: 'none',
											borderRadius: '0 15px 15px 0',
										}),
							}}
						>
							<Header
								style={{
									fontWeight: 'bolder',
									textTransform: 'uppercase',
								}}
							>
								AUTOMATIZE SEU EVENTO
							</Header>
							<p
								style={{
									fontSize: '1.1em',
									lineHeight: '1.7em',
								}}
							>
								Criamos processos automatizados que facilitam a vida de quem
								precisa gerenciar u evento esportivo.
							</p>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default Processo;
